import { Typography } from "@mui/material";
import { type OrgClientSummary } from "@trainwell/types";

export const ClientMovementStreakCellContent = ({
  client,
}: {
  client: OrgClientSummary;
}) => (
  <Typography
    variant="body2"
    sx={{ fontSize: "14px", fontWeight: "300", height: "min-content" }}
  >
    {`🔥 ${client.streak} days`}
  </Typography>
);
