import { shouldRefreshForNetworkState } from "src/lib/utils";
import { useAuth } from "src/providers/AuthProvider";
import { NetworkingState } from "src/types/NetworkingState";

export default function useShouldRefreshData({
  shouldPreventRefresh = () => false,
  shouldForceRefresh = () => false,
}: {
  shouldPreventRefresh?: () => boolean;
  shouldForceRefresh?: () => boolean;
} = {}) {
  const { isAuthenticated, loading: isAuthLoading } = useAuth();

  function shouldRefreshData<T>(state?: NetworkingState<T>): boolean {
    // Don't refresh when auth is loading. Don't refresh when unauthenticated.
    if (!isAuthenticated || isAuthLoading) return false;
    if (shouldPreventRefresh()) return false;
    if (shouldForceRefresh()) return true;
    return shouldRefreshForNetworkState(state);
  }

  return shouldRefreshData;
}
