import { Link, Stack, styled, Typography } from "@mui/material";
import type { OrgClientSummary } from "@trainwell/types";
import type { ReactNode } from "react";

const DUMMY_TRAINER_DATA = {
  name: "John Smith",
  phoneNumber: "123-456-7891",
  email: "john@smith.com",
};

const ClientDetailsContactSection = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => (
  <Stack direction="column" gap="8px">
    <Typography
      sx={{
        fontSize: { xs: "14px", lineHeight: "20px" },
        fontWeight: 500,
        color: "gray.600",
      }}
    >
      {title}
    </Typography>
    {children}
  </Stack>
);
const StyledLink = styled(Link)(() => ({
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
}));

export const ClientDetailsContact = ({
  client,
}: {
  client: OrgClientSummary;
}) => (
  <Stack direction="column" gap="24px">
    {client.phone_number && (
      <ClientDetailsContactSection title="Phone">
        <StyledLink href={`tel: ${client.phone_number}`}>
          {client.phone_number}
        </StyledLink>
      </ClientDetailsContactSection>
    )}

    {client.email && (
      <ClientDetailsContactSection title="Email">
        <StyledLink href={`mailto: ${client.email}`}>{client.email}</StyledLink>
      </ClientDetailsContactSection>
    )}

    {DUMMY_TRAINER_DATA.name && (
      <ClientDetailsContactSection title="Name">
        <StyledLink>{DUMMY_TRAINER_DATA.name}</StyledLink>
      </ClientDetailsContactSection>
    )}

    {(DUMMY_TRAINER_DATA.phoneNumber || DUMMY_TRAINER_DATA.email) && (
      <ClientDetailsContactSection title="Trainer Contact">
        {DUMMY_TRAINER_DATA.phoneNumber && (
          <StyledLink href={`tel: ${DUMMY_TRAINER_DATA.phoneNumber}`}>
            {DUMMY_TRAINER_DATA.phoneNumber}
          </StyledLink>
        )}
        {DUMMY_TRAINER_DATA.email && (
          <StyledLink href={`mailto: ${DUMMY_TRAINER_DATA.email}`}>
            {DUMMY_TRAINER_DATA.email}
          </StyledLink>
        )}
      </ClientDetailsContactSection>
    )}
  </Stack>
);
