import { Stack, Typography, type StackProps } from "@mui/material";

export const ClientDetailsSummaryCard = ({
  children,
  title,
}: {
  title: string;
} & StackProps) => (
  <Stack
    direction="column"
    sx={{
      p: "20px 24px",
      gap: "8px",
      border: "1px solid #EAECF0",
      borderRadius: "12px",
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    }}
  >
    <Typography
      sx={{
        fontSize: { xs: "16px", lineHeight: "24px" },
        fontWeight: 700,
        color: "gray.900",
        whiteSpace: "nowrap",
      }}
    >
      {title}
    </Typography>
    {children}
  </Stack>
);
