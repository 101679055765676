import { Avatar, Button, Stack, Typography } from "@mui/material";
import type { OrgClientSummary } from "@trainwell/types";
import { getFormattedDateString } from "src/lib/utils";

export const ClientDetailsHeader = ({
  client,
  isPrinting,
}: {
  client: OrgClientSummary;
  isPrinting: boolean;
}) => {
  const handlePrint = () => {
    window.open(`${window.location.href}/print`);
  };

  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: "24px",
        width: "100%",
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "24px",
        }}
      >
        {!isPrinting && (
          <Avatar
            src={client.headshot_url}
            sx={{
              width: "160px",
              height: "160px",
              bgcolor: "#E0E0E0",
              border: "4px solid white",
              boxShadow:
                "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
            }}
          />
        )}
        <Stack direction="column" sx={{ pt: "64px" }}>
          <Typography variant="h1" sx={{ color: "gray.900", fontSize: "30px" }}>
            {`${client.first_name} ${client.last_name}`}
          </Typography>
          <Typography sx={{ color: "gray.600", fontSize: { xs: "16px" } }}>
            {`Active patient since ${getFormattedDateString(client.date_membership_started)}`}
          </Typography>
        </Stack>
      </Stack>

      {!isPrinting && (
        <Button
          variant="contained"
          sx={{ fontSize: "16px", flexShrink: 0 }}
          startIcon={
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6397 2.79069V3.32214H13.5711V2.7235C13.5711 2.0088 13.2375 1.67895 12.5149 1.67895H5.25676C4.53404 1.67895 4.20047 2.0088 4.20047 2.7235L4.20048 3.32214H3.13184V2.79069C3.13184 1.33687 3.91633 0.689362 5.26911 0.689362H12.5025C13.9171 0.689362 14.6397 1.33687 14.6397 2.79069Z"
                fill="white"
              />
              <path
                d="M17.7715 5.53953V12.9308C17.7715 14.403 16.9931 15.1483 15.4983 15.1483H14.5965V14.1281H15.5107C16.289 14.1281 16.6658 13.7433 16.6658 12.9797V5.49066C16.6658 4.72709 16.289 4.34836 15.5107 4.34836H2.26081C1.4825 4.34836 1.09952 4.72709 1.09952 5.49066V12.9797C1.09952 13.7433 1.4825 14.1281 2.26081 14.1281H3.17502V15.1483H2.27317C0.778313 15.1483 0 14.403 0 12.9308V5.53953C0 4.06737 0.84626 3.32214 2.27317 3.32214H15.4983C16.9931 3.32214 17.7715 4.06737 17.7715 5.53953ZM14.6397 6.26033C14.6397 6.78565 14.2011 7.21938 13.6761 7.21938C13.1387 7.21938 12.7001 6.79791 12.7001 6.26033C12.7001 5.74111 13.1387 5.3013 13.6761 5.3013C14.2011 5.3013 14.6397 5.74111 14.6397 6.26033Z"
                fill="white"
              />
              <path
                d="M4.73785 17.5H13.0337C14.0776 17.5 14.5965 17.0297 14.5965 15.9607V9.64443C14.5965 8.5755 14.0776 8.10511 13.0337 8.10511H4.73785C3.73099 8.10511 3.17505 8.5755 3.17505 9.64443V15.9607C3.17505 17.0297 3.69392 17.5 4.73785 17.5ZM5.05288 16.4799C4.51548 16.4799 4.28075 16.2478 4.28075 15.7102V9.88269C4.28075 9.35739 4.51548 9.12526 5.05288 9.12526H12.7248C13.2622 9.12526 13.4908 9.35739 13.4908 9.88269V15.7102C13.4908 16.2478 13.2622 16.4799 12.7248 16.4799H5.05288ZM5.99798 11.8436H11.7859C12.033 11.8436 12.2183 11.6541 12.2183 11.4159C12.2183 11.1838 12.033 11.0005 11.7859 11.0005H5.99798C5.74472 11.0005 5.5594 11.1838 5.5594 11.4159C5.5594 11.6541 5.74472 11.8436 5.99798 11.8436ZM5.99798 14.6046H11.7859C12.033 14.6046 12.2183 14.4214 12.2183 14.1892C12.2183 13.9448 12.033 13.7555 11.7859 13.7555H5.99798C5.74472 13.7555 5.5594 13.9448 5.5594 14.1892C5.5594 14.4214 5.74472 14.6046 5.99798 14.6046Z"
                fill="white"
              />
            </svg>
          }
          onClick={handlePrint}
        >
          Print
        </Button>
      )}
    </Stack>
  );
};
