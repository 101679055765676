import { type OrgClientSummary } from "@trainwell/types";
import {
  ClientNutritionConsistencyCellContent,
  ClientWorkoutConsistencyCellContent,
} from "../Shared/ClientConsistencyCellContent";
import { ClientMovementStreakCellContent } from "../Shared/ClientMovementStreakCellContent";
import { ClientNameCellContent } from "../Shared/ClientNameCellContent";
import { ClientStatusCellContent } from "../Shared/ClientStatusCellContent";
import { type ClientTableColumn } from "./types";

export const ClientCellContent = ({
  columnKey,
  client,
}: {
  columnKey: ClientTableColumn["key"];
  client: OrgClientSummary;
}): JSX.Element | null => {
  switch (columnKey) {
    case "name":
      return <ClientNameCellContent client={client} />;
    case "status":
      return <ClientStatusCellContent client={client} />;
    case "nutrition_consistency":
      return <ClientNutritionConsistencyCellContent client={client} />;
    case "workout_consistency":
      return <ClientWorkoutConsistencyCellContent client={client} />;
    case "movement_streak":
      return <ClientMovementStreakCellContent client={client} />;
  }
};
