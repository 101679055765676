import { Stack } from "@mui/material";
import type { OrgClientSummary } from "@trainwell/types";
import {
  ClientNutritionConsistencyCellContent,
  ClientWorkoutConsistencyCellContent,
} from "../Shared/ClientConsistencyCellContent";
import { ClientMovementStreakCellContent } from "../Shared/ClientMovementStreakCellContent";
import { ClientStatusCellContent } from "../Shared/ClientStatusCellContent";
import { ClientDetailsContact } from "./ClientDetailsContact";
import { ClientDetailsHeader } from "./ClientDetailsHeader";
import { ClientDetailsSummaryCard } from "./ClientDetailsSummaryCard";

export const ClientDetailsSuccessBody = ({
  client,
  isPrinting,
}: {
  client: OrgClientSummary;
  isPrinting: boolean;
}) => (
  <>
    <ClientDetailsHeader client={client} isPrinting={isPrinting} />
    <Stack
      direction="row"
      sx={{
        width: "100%",
        gap: "96px",
      }}
    >
      <ClientDetailsContact client={client} />
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          flexWrap: "wrap",
          gap: "20px",
          "& > *": {
            flexBasis: "calc(50% - 10px)",
            flexGrow: 1,
            minWidth: "200px",
          },
        }}
      >
        <ClientDetailsSummaryCard title="Status">
          <ClientStatusCellContent client={client} />
        </ClientDetailsSummaryCard>
        <ClientDetailsSummaryCard title="Movement Streak">
          <ClientMovementStreakCellContent client={client} />
        </ClientDetailsSummaryCard>
        <ClientDetailsSummaryCard title="Workout Consistency">
          <ClientWorkoutConsistencyCellContent client={client} />
        </ClientDetailsSummaryCard>
        <ClientDetailsSummaryCard title="Nutrition Consistency">
          <ClientNutritionConsistencyCellContent client={client} />
        </ClientDetailsSummaryCard>
      </Stack>
    </Stack>
  </>
);
