import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingPage from "src/components/misc/LoadingPage";
import { APP_ROUTES } from "src/constants/AppRoutes";
import { isValidEmail } from "src/lib/misc";

export default function FinishSignInPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const foundEmail = window.localStorage.getItem("emailForSignIn");

  if (!email && foundEmail) {
    setEmail(foundEmail);
  }

  useEffect(() => {
    const executeAsync = async () => {
      const auth = getAuth();

      if (!isSignInWithEmailLink(auth, window.location.href)) {
        setError("The link was invalid");
        setLoading(false);
        return;
      }
      if (!email) {
        setLoading(false);
        return;
      }

      try {
        const result = await signInWithEmailLink(
          auth,
          email,
          window.location.href,
        );
        /*
          Some properties you may be interested in:
          - result.user.email
          - result.user.reloadUserInfo.customAttributes // A stringified object containing userId.
        */
        window.localStorage.removeItem("emailForSignIn");
        navigate(APP_ROUTES.HOME.path());
      } catch (error) {
        setError("Network request failed");
      } finally {
        setLoading(false);
      }
    };

    executeAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  if (loading) {
    return <LoadingPage message="Signing in with email" />;
  }

  return (
    <Box>
      <Dialog
        open={!error}
        onClose={() => {
          navigate(APP_ROUTES.SIGN_IN.path());
        }}
      >
        <DialogTitle>Re-enter your email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the same email you used to sign in.
          </DialogContentText>
          <TextField
            autoFocus
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            placeholder="alexsmith@example.com"
            value={newEmail}
            onChange={(event) => {
              setNewEmail(event.target.value);
            }}
            sx={{ my: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              navigate(APP_ROUTES.SIGN_IN.path());
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (isValidEmail(newEmail)) {
                setEmail(newEmail);
              }
            }}
            disabled={!isValidEmail(newEmail)}
          >
            Sign in
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={!!error}
        onClose={() => {
          navigate(APP_ROUTES.SIGN_IN.path());
        }}
      >
        <DialogTitle>Something went wrong</DialogTitle>
        <DialogContent>
          <DialogContentText>{error}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              navigate(APP_ROUTES.SIGN_IN.path());
            }}
          >
            Try Again
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
