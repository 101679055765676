import { Button, Stack, Typography } from "@mui/material";

export const ClientDetailsErrorBody = ({
  error,
  onTryAgain,
}: {
  error?: string;
  onTryAgain: () => void;
}) => (
  <Stack
    direction="column"
    gap="10px"
    sx={{
      borderRadius: "12px",
      padding: "24px",
      bgcolor: "error.50",
      width: "100%",
      height: "calc(100vh - 200px)",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Typography sx={{ color: "error.500" }}>
      {error ?? "Something went wrong"}
    </Typography>
    <Button variant="text" onClick={onTryAgain}>
      Try Again
    </Button>
  </Stack>
);
