import { TablePageContainer } from "../Shared/Table/PageContainer";
import { ClientsTable } from "./ClientsTable";

export function Clients() {
  return (
    <TablePageContainer sx={{ mt: "64px", mb: "64px" }}>
      <ClientsTable />
    </TablePageContainer>
  );
}
