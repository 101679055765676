import { Avatar, Stack, Typography } from "@mui/material";
import { type OrgClientSummary } from "@trainwell/types";
import { getFormattedDateString } from "../../lib/utils";

export const ClientNameCellContent = ({
  client,
}: {
  client: OrgClientSummary;
}) => (
  <Stack direction="row" sx={{ gap: "12px" }}>
    <Avatar src={client.headshot_url} />
    <Stack direction="column" sx={{ gap: "0px" }}>
      <Typography
        sx={{
          fontSize: { xs: "14px" },
          fontWeight: 500,
          color: "gray.900",
          whiteSpace: "nowrap",
        }}
      >
        {`${client.first_name} ${client.last_name}`}
      </Typography>

      <Typography
        sx={{
          fontSize: { xs: "14px" },
          fontWeight: 300,
          color: "gray.600",
          whiteSpace: "nowrap",
        }}
      >
        {`Joined ${getFormattedDateString(client.date_membership_started)}`}
      </Typography>
    </Stack>
  </Stack>
);
