import { Box, Stack } from "@mui/material";

export const ClientDetailsLoadingBody = () => (
  <Box className="shimmer" sx={{ display: "contents" }}>
    <Stack
      className="shimmer"
      direction="row"
      sx={{
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: "24px",
        width: "100%",
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            width: "160px",
            height: "160px",
            borderRadius: "80px",
            bgcolor: "gray.200",
          }}
        />
        <Stack direction="column" sx={{ pt: "56px", gap: "8px" }}>
          <Box
            sx={{
              width: "200px",
              height: "24px",
              borderRadius: "12px",
              bgcolor: "gray.200",
            }}
          />
          <Box
            sx={{
              width: "320px",
              height: "20px",
              borderRadius: "12px",
              bgcolor: "gray.200",
            }}
          />
        </Stack>
      </Stack>
    </Stack>

    <Stack
      direction="row"
      sx={{
        width: "100%",
        gap: "96px",
      }}
    >
      <Stack direction="column" gap="24px">
        <Box
          sx={{
            width: "200px",
            height: "24px",
            borderRadius: "12px",
            bgcolor: "gray.200",
          }}
        />
        <Box
          sx={{
            width: "320px",
            height: "20px",
            borderRadius: "12px",
            bgcolor: "gray.200",
          }}
        />
      </Stack>

      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          flexWrap: "wrap",
          gap: "20px",
          "& > *": {
            flexBasis: "calc(50% - 10px)",
            flexGrow: 1,
            minWidth: "200px",
          },
        }}
      >
        <Box
          sx={{
            height: "146px",
            borderRadius: "12px",
            bgcolor: "gray.200",
          }}
        />
        <Box
          sx={{
            height: "146px",
            borderRadius: "12px",
            bgcolor: "gray.200",
          }}
        />
        <Box
          sx={{
            height: "146px",
            borderRadius: "12px",
            bgcolor: "gray.200",
          }}
        />
        <Box
          sx={{
            height: "146px",
            borderRadius: "12px",
            bgcolor: "gray.200",
          }}
        />
      </Stack>
    </Stack>
  </Box>
);
