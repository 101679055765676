import type { Organization, OrgClientSummary } from "@trainwell/types";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "src/constants/AppRoutes";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getClientSummaries } from "src/redux/slices/clientsSlice";
import type { RootState } from "src/redux/stores/store";
import { NetworkingState } from "src/types/NetworkingState";
import Table from "../Shared/Table";
import { TableState, type TableDelegateMethods } from "../Shared/Table/types";
import { ClientCellContent } from "./ClientCell";
import { type ClientTableColumn } from "./types";

const columns: ClientTableColumn[] = [
  { key: "name", title: "Name" },
  { key: "status", title: "Status" },
  { key: "movement_streak", title: "Movement Streak" },
  { key: "workout_consistency", title: "Workout Consistency" },
  { key: "nutrition_consistency", title: "Nutrition Consistency" },
];

function getTitle(organizationState: NetworkingState<Organization>) {
  if (organizationState.status !== "succeeded") return "";
  return `${organizationState.data.name} Patients`;
}

function getSubtitle(organizationState: NetworkingState<Organization>) {
  if (organizationState.status !== "succeeded") return "";
  return `Manage your clinic's patients that are currently active in the ${organizationState.data.name} program.`;
}

function getTableState(
  state: RootState["clients"]["clientSummariesState"],
  onTryAgain: () => void,
) {
  switch (state.status) {
    case "idle":
      return TableState.empty("No clients found.");
    case "loading":
      return TableState.loading("Fetching clients");
    case "succeeded":
      return state.data.data.length === 0
        ? TableState.empty("No clients found.")
        : TableState.succeeded(
            state.data.data,
            state.data.page,
            state.data.totalPages,
          );
    case "failed":
      return TableState.failed(state.error, onTryAgain);
  }
}

export function ClientsTable() {
  const organizationState = useAppSelector(
    (state) => state.organization.organizationState,
  );
  const clientSummariesState = useAppSelector(
    (state) => state.clients.clientSummariesState,
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const refreshClients = useCallback(
    (page?: number) => {
      dispatch(getClientSummaries({ page: page }));
    },
    [dispatch],
  );

  const tableState: TableState<OrgClientSummary> = useMemo(
    () =>
      getTableState(clientSummariesState, () =>
        refreshClients(
          clientSummariesState.status === "succeeded"
            ? clientSummariesState.data.page
            : undefined,
        ),
      ),
    [clientSummariesState, refreshClients],
  );

  const handleSelectPage = useCallback(
    (page: number) => {
      refreshClients(page);
    },
    [refreshClients],
  );

  const delegateMethods: TableDelegateMethods<
    OrgClientSummary,
    ClientTableColumn
  > = useMemo(
    () => ({
      keyForRow: (item) => item.user_id,
      renderCell: (item, column) => (
        <ClientCellContent client={item} columnKey={column.key} />
      ),
      onClickRow: (item) =>
        navigate(APP_ROUTES.CLIENTS.detail.path(item.user_id)),
    }),
    [navigate],
  );

  return (
    <Table<OrgClientSummary, ClientTableColumn>
      headerConfig={{
        title: getTitle(organizationState),
        subtitle: getSubtitle(organizationState),
      }}
      footerConfig={{
        onSelectPage: handleSelectPage,
      }}
      columns={columns}
      tableState={tableState}
      delegateMethods={delegateMethods}
    />
  );
}
